/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, Button, ListItem, Radio, Stack, Typography } from "@mui/material";

import "./MyCardList.css";
import BlueCard from "./BlueCard";
import YellowCard from "./YellowCard";
import { paymentAPI } from "src/services/api";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { Link } from "react-router-dom";

export default function MyDefaultCard({
  getCards,
  savedCards,
  handleSubmit,
  showForm,
}) {
  const { setLoading, walletBalance } = useSharedState();
  const {
    customerId,
    setPaymentMethodId,
    selectedPayment,
    setSelectedPayment,
    setShowRechargeWallet,
  } = usePaymentState();
  const [showAllCards, setShowAllCards] = useState(false);

  const handlePaymentMethodChange = (event) => {
    if (event.target.value === "card") {
      setShowRechargeWallet(false);
    }
    setSelectedPayment(event.target.value);
  };
  // useEffect(() => {
  // setLoading(true);
  // console.log(paymentMethods);
  // paymentAPI
  //   .getPaymentMethodByCustomer()
  //   .then((res) => {
  //     ;
  //     console.log(res);
  //     if (res.data.success === true) {
  //       setPaymentMethods([]);
  //       if (res.data.data.paymentMethods.length > 0) {
  //         console.log(res.data.data.paymentMethods);
  //         res.data.data.paymentMethods.forEach(
  //           ({ billing_details, card, id }, index) => {
  //             setPaymentMethods((previousItem) => [
  //               ...previousItem,
  //               {
  //                 id: id,
  //                 cardName: billing_details.name,
  //                 last4: card.last4,
  //                 exp_month: card.exp_month,
  //                 exp_year: card.exp_year,
  //               },
  //             ]);
  //           }
  //         );
  //       }
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   })
  //   .catch((err) => {

  //     setLoading(false);
  //     toast.error("Something went wrong!!");
  //   });
  // }, [paymentMethodId]);

  const handleChange = async (newValue) => {
    //setLoading(true);
    await paymentAPI
      .setDefaultCard({ customerId, paymentMethodId: newValue })
      .then((res) => {
        if (res.data.success === true) {
          setPaymentMethodId(newValue);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!!");
      });
  };

  const handleChangeShowCards = () => {
    setShowAllCards(!showAllCards);
    setSelectedPayment("card");
  };
  return (
    <>
      {/* {paymentMethods ?? ( */}
      <>
        <Box className="MyCardListContainer">
          <Stack direction="row">
            <ListItem>
              <Typography variant="h4">My Cards</Typography>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                className="LoginButton"
                sx={{ py: 1, px: 2 }}
                onClick={handleSubmit}
              >
                {showForm ? "Cancel" : "Add New Card"}
              </Button>
            </ListItem>
          </Stack>
          {savedCards.length === 0 && getCards === false ? (
            <>Loading...Please wait.</>
          ) : savedCards.length === 0 && getCards === true ? (
            <>No cards found, please add cards!</>
          ) : (
            <Box className="MyCardList" paddingTop={2}>
              {savedCards.map((item, index) =>
                showAllCards ? (
                  index % 2 === 0 ? (
                    <BlueCard
                      showAllCards={showAllCards}
                      key={item.id}
                      cardData={{ index, ...item }}
                      onChange={handleChange}
                    />
                  ) : (
                    <YellowCard
                      showAllCards={showAllCards}
                      key={item.id}
                      cardData={{ index, ...item }}
                      onChange={handleChange}
                    />
                  )
                ) : item.isDefault ? (
                  index % 2 === 0 ? (
                    <BlueCard
                      showAllCards={showAllCards}
                      key={item.id}
                      cardData={{ index, ...item }}
                      onChange={handleChange}
                    />
                  ) : (
                    <YellowCard
                      showAllCards={showAllCards}
                      key={item.id}
                      cardData={{ index, ...item }}
                      onChange={handleChange}
                    />
                  )
                ) : (
                  ""
                )
              )}
            </Box>
          )}
          {!showAllCards ? (
            <>
              <Box>
                <Stack direction="row">
                  <ListItem>
                    {" "}
                    <Radio
                      checked={selectedPayment === "card"}
                      onChange={handlePaymentMethodChange}
                      name="radio-buttons"
                      value="card"
                    />
                    Card
                  </ListItem>
                  <ListItem>
                    <Link
                      className="chooseAnother"
                      onClick={() => {
                        handleChangeShowCards();
                      }}
                    >
                      Choose Another
                    </Link>
                  </ListItem>
                </Stack>
              </Box>
              <Box className="PayWithWallet" sx={{ width: "80%" }}>
                <Stack direction="row">
                  <ListItem sx={{ width: "50%", paddingLeft: "27px" }}>
                    OR
                  </ListItem>
                  <ListItem
                    className="payWithWalletButton"
                    sx={{ paddingRight: "0px" }}
                  >
                    {" "}
                    <Radio
                      checked={selectedPayment === "wallet"}
                      onChange={handlePaymentMethodChange}
                      name="radio-buttons"
                      value="wallet"
                    />
                    <Stack sx={{ paddingTop: "0px" }}>
                      <ListItem>
                        <Typography variant="h4">Pay With Wallet</Typography>
                      </ListItem>
                      <ListItem>
                        Balance ${parseFloat(walletBalance.toFixed(2))}
                      </ListItem>
                    </Stack>
                  </ListItem>
                </Stack>
              </Box>
            </>
          ) : (
            <Box>
              <Stack direction="row">
                <ListItem>
                  <Link
                    className="chooseAnother"
                    onClick={() => {
                      handleChangeShowCards();
                    }}
                  >
                    Pay With Wallet
                  </Link>
                </ListItem>
              </Stack>
            </Box>
          )}
        </Box>
      </>
    </>
  );
}
