/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import "../../style/style.css";
import logo from "../../Assets/512_-removebg-preview1.png";
import Beta from "../../Assets/BetaIMG.png";
import "./Header.css";
import { Button, Typography } from "@mui/material";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AiProfile from "../../Assets/app-icon.png";
import { jobAPI } from "src/services/jobApis";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function Login() {
  const {
    isClicked,
    setJobId,
    setIsClicked,
    setLoading,
    showSiderBar,
    setShowSiderBar,
    selectedComponent,
    setSelectedComponent,
    jobId,
  } = useSharedState();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const handleCreateJob = (event) => {
    event.preventDefault();
    setLoading(true);
    jobAPI
      .createJob({ name: "yemo" })
      .then((res) => {
        setIsClicked(!isClicked);
        setJobId(res.data.data.id);
        setLoading(false);
        navigate(`/dashboard/chat/${res.data.data.id}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  const url = window.location.href;
  React.useEffect(() => {
    if (jobId !== null && url.includes("dashboard/chat")) {
      setSelectedComponent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, url]);
  React.useEffect(() => {
    setSelectedComponent(null);
  }, []);
  return (
    <Box
      className="header"
      sx={{
        // display: "none",
        justifyContent: showSiderBar
          ? "space-between"
          : selectedComponent !== null
          ? "space-between"
          : "flex-end",
          px:3
      }}
    >
      {showSiderBar ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="logo" height={29} />
          <img src={Beta} alt="beta logo" height="15px" width="38px" />
          <Box className="backbutton">
            <Button
              sx={{ minWidth: "0px", padding: "0px" }}
              onClick={() => {
                setShowSiderBar(false);
              }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {!showSiderBar && selectedComponent !== null ? (
        <Box
          sx={{
            my: 2,
            px: 2,
            display: "flex",
            alignItems: "center",
            fontWeight: "700",
          }}
        >
          <Button
            sx={{ minWidth: "40px", height: "40px" }}
            onClick={() => {
              setJobId(null);
              navigate("/dashboard");
              setSelectedComponent(null);
            }}
          >
            <ChevronLeftIcon />
          </Button>
          {selectedComponent}
        </Box>
      ) : (
        <Typography
          sx={{
            justifyContent: "flex-start",
            color: "#666666",
            fontSize: 12,
            fontFamily: "Inter",
            fontStyle: "italic",
            fontWeight: "400",
            wordWrap: "break-word",
            left: 0,
          }}
        >
          "I believe AI is going to change the world more than anything in the
          history of humanity. More than electricity."
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {role === "1" && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              className="LoginButton tour-create-job"
              sx={(theme) => ({
                py: 1,
                px: 2,
                display: "none",
                [theme.breakpoints.up("md")]: { display: "block" },
              })}
              onClick={handleCreateJob}
            >
              Create Job
            </Button>
            <Button
              sx={(theme) => ({
                flexDirection: "column",
                padding: "0 10px",
                borderRadius: "6px !important",
                display: "none !important",
                [theme.breakpoints.down("md")]: { display: "flex !important" },
              })}
            >
              <img src={AiProfile} alt="aiprofile" className="size-30" />
              <Typography
                variant="p"
                sx={{ fontSize: "8px", color: "#018385", fontWeight: "600" }}
              >
                Your AI Co-Pilot
              </Typography>
            </Button>
            <Fab
              aria-label="add"
              onClick={handleCreateJob}
              className="fab-add-job"
              sx={(theme) => ({
                bgcolor: "#018385",
                color: "#fff",
                display: "none",
                [theme.breakpoints.down("md")]: { display: "flex" },
              })}
            >
              <AddIcon />
            </Fab>
          </Box>
        )}
      </Box>
    </Box>
  );
}
