/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./ActionButtons.css";
import "./PopUp.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { socket } from "../Main/Message";
import { jobAPI } from "src/services/jobApis";
import { adminApi } from "src/services/api";

export default function PopUp() {
  const [open, setOpen] = React.useState(false);
  const [staticData, setStaticData] = useState({});
  const [scroll, setScroll] = React.useState("paper");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { jobId, setUpdatedRightSideBar } = useSharedState();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { userJob, submitContract, setSubmitContract } = useJobSharedState();
  const { setChatLoading } = useSharedState();
  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    setIsPopupVisible(false);
  }, [jobId]);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    //setIsPopupVisible(true);
  };

  useEffect(() => {
    setSubmitContract(false);
  }, [jobId]);

  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const handleSubmitContract = () => {
    setChatLoading(true);
    submitContractAPI(jobId);
  };

  const submitContractAPI = (jobId) => {
    jobAPI
      .submitContract(jobId)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setSubmitContract(true);
          handleClose();
          const messageData = {
            jobId: jobId,
          };
          socket.emit("yemoMessage", { messageData });
          setUpdatedRightSideBar(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const getStatic = () => {
    adminApi
      .getStaticData({ name: "submitContract" })
      .then((res) => {
        setStaticData(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!");
      });
  };
  useEffect(() => {
    getStatic();
  }, []);
  return (
    <Box sx={{ p: 2 }}>
      {!isPopupVisible &&
      userJob.jobStatus === "pending" &&
      submitContract === false ? (
        <Button
          variant="contained"
          className="LoginButton"
          onClick={handleClickOpen("paper")}
          sx={{ width: "100%" }}
        >
          Submit Contract
        </Button>
      ) : (
        ""
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Submit Contract
          <Box sx={{ fontSize: "16px", mt: 2 }}>Accepting the terms</Box>
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {staticData?.data?.split("\n").map((fixData, index) => (
              <div key={fixData}>
                <p>{fixData}</p>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Box>
            <FormControlLabel
              required
              control={
                <Checkbox
                  onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
              }
              label="I accept the Terms and Conditions"
            />
          </Box>

          {/* <Box className="ActionButtonsCnt"> */}
          <Button
            variant="contained"
            disabled={!isCheckboxChecked}
            className="LoginButton"
            onClick={() => {
              handleSubmitContract();
            }}
          >
            Submit Contract
          </Button>
          {/* </Box> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
