import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ProjectName from "./ProjectName";
import "./ProjectDetails.css";
import { Box } from "@mui/system";
import React from "react";
import { useJobSharedState } from "src/utils/jobStateProvider";

const ProjectDetails = () => {
  const { userJob } = useJobSharedState();

  return (
    <div>
      <Box sx={(theme) => ({ [theme.breakpoints.down("md")]: { p: 2 } })}>
        <ProjectName name={userJob.name} />
      </Box>

      <Box className="ProjectDesc">
        <List
          sx={(theme) => ({
            width: "100%",
            bgcolor: "background.paper",
            [theme.breakpoints.down("md")]: { maxWidth: "100%" },
          })}
        >
          <ListItem>
            <ListItemText
              primary="Type of project"
              secondary={
                userJob.category
                  ? userJob.subCategory
                    ? `${userJob.category} -- ${userJob.subCategory}`
                    : userJob.category
                  : null
              }
            />
          </ListItem>
          <Divider  />

          <ListItem>
            <ListItemText
              primary="Description of project"
              secondary={userJob.description}
            />
          </ListItem>
          <Divider  />

          <ListItem>
            <ListItemText primary="Duration " secondary={userJob.duration} />
          </ListItem>
          <Divider  />

          <ListItem>
            <ListItemText
              primary="Instructions "
              secondary={userJob.instructions}
            />
          </ListItem>
          <Divider  />
        </List>
      </Box>
    </div>
  );
};

export default ProjectDetails;
