/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ProjectName from "./ProjectName";
import TextField from "@mui/material/TextField";
import "./ProjectDetails.css";
import { Box } from "@mui/system";
import { Tooltip, Typography } from "@mui/material";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

const EditableProjectDetails = ({ isEditing, setIsEditing, save, setSave }) => {
  const { userJob, updateJob } = useJobSharedState();
  const { jobId } = useSharedState();
  const [editedDescription, setEditedDescription] = React.useState(
    userJob.description || ""
  );
  const [editedDuration, setEditedDuration] = React.useState(
    userJob.duration || ""
  );
  const [editedInstructions, setEditedInstructions] = React.useState(
    userJob.instructions || ""
  );
  const handleSave = () => {
    const updatedUserJob = {
      ...userJob,
      description: editedDescription,
      duration: editedDuration,
      instructions: editedInstructions,
    };
    updateJob(updatedUserJob);
    jobAPI
      .updateJob(updatedUserJob, jobId)
      .then((res) => {
        setIsEditing(false);
        setSave(false);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  React.useEffect(() => {
    if (save) {
      handleSave();
    }
  }, [save]);
  const isMultiline = editedDescription.length > 20;
  return (
    <div>
      <ProjectName />

      <Box className="ProjectDesc editForm">
        <List
          sx={(theme) => ({
            width: "100%",
            bgcolor: "background.paper",
            [theme.breakpoints.down("md")]: { maxWidth: "100%" },
          })}
        >
          <ListItem>
            <ListItemText>
            <Tooltip title={"This field is non-editable." } arrow>
              <Typography variant="p">Type of project </Typography>
             
              <TextField
                label=""
                variant="outlined"
                value={
                  userJob.category
                    ? userJob.subCategory
                      ? `${userJob.category} -- ${userJob.subCategory}`
                      : userJob.category
                    : null
                }
                fullWidth
                margin="normal"
                disabled
              />
              </Tooltip>
            </ListItemText>
          </ListItem>

          <Divider variant="inset" component="li" />

          <ListItem>
            <ListItemText >
              <Typography variant="p">Description of project</Typography>

              <TextField
                variant="outlined"
                value={editedDescription}
                fullWidth
                margin="normal"
                onChange={(e) => setEditedDescription(e.target.value)} 
                multiline={isMultiline}
                sx={{background:"#EFEFEF", borderRadius: "10px",border:"none"}}
              />
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem>
            <ListItemText>
              <Typography variant="p">Duration</Typography>

              <TextField
                label=""
                variant="outlined"
                value={editedDuration}
                fullWidth
                margin="normal"
                onChange={(e) => setEditedDuration(e.target.value)}
              />
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />

          <ListItem>
            <ListItemText>
              <Typography variant="p">Instructions</Typography>

              <TextField
                label=""
                variant="outlined"
                value={editedInstructions}
                onChange={(e) => setEditedInstructions(e.target.value)}
                fullWidth
                margin="normal"
              />
            </ListItemText>
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        <Box sx={{ position: "relative" }}>
          {/* <Link href="#" onClick={handleSave}>
            save
          </Link> */}
        </Box>
      </Box>
    </div>
  );
};

export default EditableProjectDetails;
