/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import "./PaymentSummary.css";
import CloseIcon from "@mui/icons-material/Close";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";
import { stripePublishableKey } from "src/constants/api.constants";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { socket } from "src/pages/Main/Message";
import { jobAPI } from "src/services/jobApis";
import { paymentAPI } from "src/services/api";
import { userAPI } from "src/services/userApis";
import { Link } from "react-router-dom";

// const stripePromise = loadStripe(stripePublishableKey); // Replace with your actual publishable key

const PaymentSummary = ({ paymentId , setJobPrice }) => {
  const [error, setError] = useState("");
  const {
    jobId,
    setLoading,
    confirmPayment,
    setConfirmPayment,
    walletBalance,
    setWalletBalance,
    setUpdatedRightSideBar,
    showCardComponent,
    setShowCardComponent,
  } = useSharedState();
  const [promoCode, setPromoCode] = useState("");
  const {
    paymentMethodId,
    customerId,
    selectedPayment,
    setSelectedPayment,
    showRechargeWallet,
    setShowRechargeWallet,
  } = usePaymentState();
  const { userJob } = useJobSharedState();
  const initialState = {
    basePrice: 0,
    platformFee: 0,
    taxAmount: 0,
    netAmount: 0,
    promoCodeDiscount: 0,
    finalPrice: 0,
    codeName: 0,
  };
  const [paymentSummaryData, setPaymentSummaryData] = useState(initialState);
  const fetchPaymentSummary = async (jobId, reqData = {}) => {
    try {
      // console.log("userjob", userJob);
      if (userJob.price !== undefined) {
        await paymentAPI
          .getJobPaymentSummary(jobId, reqData)
          .then(async (res) => {
            if (res.data.success) {
              const {
                basePrice,
                platformFee,
                taxAmount,
                netAmount,
                promoCodeDiscount,
                finalPrice,
              } = res.data.data.price;
              setPaymentSummaryData({
                basePrice: basePrice,
                platformFee: platformFee,
                taxAmount: taxAmount,
                netAmount: netAmount,
                promoCodeDiscount: promoCodeDiscount,
                finalPrice: finalPrice.toFixed(2),
                codeName: reqData.codeName || null,
              });
              setJobPrice(parseInt(finalPrice.toFixed(2)));
              setPromoCode(reqData.codeName || "");
              setPaymentSummaryData((prevstate) => ({
                ...prevstate,
                codeName: "",
              }));
            } else {
              setPaymentSummaryData((prevstate) => ({ ...prevstate }));
              toast.error(res.data.message);
              // setPaymentSummaryData(initialState);
              // toast.error("Something went wrong!!");
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!!");
          });
      }
    } catch (error) {
      toast.error("Something went wrong!!");
      console.error("Error fetching customer ID:", error);
    }
  };
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeObject = await loadStripe(stripePublishableKey);
        setStripe(stripeObject);
      } catch (error) {
        console.log("Error loading Stripe:", error);
      }
    };

    initializeStripe();
    // Fetch customer ID from your server/database and update the state
    fetchPaymentSummary(jobId);
  }, []);

  const addTransactions = async (paymentIntentId, price, status) => {
    await paymentAPI
      .createTransaction({ paymentIntentId, price, status, jobId })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        toast.error("Seomthing went wrong!");
      });
  };

  const getUser = () => {
    userAPI
      .getUserById()
      .then((res) => {
        setWalletBalance(res.data.body.data.wallet.balance);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!");
      });
  };
  const payWithWalletBalance = async (price) => {
    setLoading(true);
    setError("");
    await paymentAPI
      .payWithWallet({ price })
      .then(async (res) => {
        await addTransactions(1, paymentSummaryData.finalPrice, "success");
        await jobAPI
          .confirmPayment({
            jobId,
            codeName: promoCode,
            paymentIntentId: 1,
          })
          .then(async (res) => {});
        toast.success("Payment successfull");
        console.log("socket",socket.connect().connected);
            socket.emit('getWaitingJob',jobId)
        setUpdatedRightSideBar(true);
        getUser();
        setConfirmPayment(true);
        const messageData = {
          jobId: jobId,
          message: `Payment successfull`,
        };
        socket.emit("yemoMessage", { messageData });
      })
      .catch((err) => {
        console.log("errooor", err);
        toast.error("Something went wrong!!");
      });
    setLoading(false);
  };
  const checkOutPayment = async () => {
    if (selectedPayment === "wallet") {
      if (parseFloat(walletBalance) < paymentSummaryData.finalPrice) {
        setShowRechargeWallet(true);
      } else {
        setShowCardComponent(true);
        await payWithWalletBalance(paymentSummaryData.finalPrice);
      }
    } else {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    await paymentAPI
      .createPaymentIntent({
        customerId,
        finalAmount: paymentSummaryData.finalPrice,
      })
      .then(async (res) => {
        if (res.data.success) {
          // Use the PaymentIntent's client_secret to confirm the payment
          const confirmResult = await stripe.confirmCardPayment(
            res.data.data.client_secret,
            {
              payment_method: paymentId,
            }
          );

          if (confirmResult.error) {
            console.log(
              "payment confirm error",
              confirmResult.error.payment_intent.id
            );
            await addTransactions(
              confirmResult.error.payment_intent.id,
              paymentSummaryData.finalPrice,
              "failed"
            );
            console.log(confirmResult.error);
            // toast.error("Something went wrong!!");
            setError(confirmResult.error.message);
          } else {
            await addTransactions(
              confirmResult.paymentIntent.id,
              paymentSummaryData.finalPrice,
              "success"
            );
            await jobAPI
              .confirmPayment({
                jobId,
                codeName: promoCode,
                paymentIntentId: confirmResult.paymentIntent.id,
              })
              .then(async (res) => {});
            toast.success("Payment successfull");
            console.log("socket",socket.connect().connected);
            socket.emit('getWaitingJob',jobId)
            setConfirmPayment(true);
            console.log("Payment successful:", confirmResult.paymentIntent);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("errooor", err);
        setLoading(false);
        toast.error("Something went wrong!!");
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPaymentSummaryData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleApplyPromoCode = async () => {
    fetchPaymentSummary(jobId, {
      codeName: paymentSummaryData.codeName || "",
    });
  };

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    setPromoCode("");
    setPaymentSummaryData((prevstate) => ({ ...prevstate, codeName: "" }));
    fetchPaymentSummary(jobId);
    console.info("You clicked the delete icon.");
  };

  const PromocodeData = [
    { id: 1, description: "Promo Code", promodata: "Summer23", amount: 14 },
  ];

  return (
    <Box className="PaymentSummaryBox">
      <Typography variant="h6">Payment Summary</Typography>

      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="payment summary">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <p>
                  <span>Base Price</span>
                  <span>${paymentSummaryData.basePrice}</span>
                </p>
                <p>
                  <span>Platform fees</span>
                  <span>${paymentSummaryData.platformFee}</span>
                </p>
                <p>
                  <span>Tax</span>
                  <span>${paymentSummaryData.taxAmount}</span>
                </p>

                {/* {paymentData.map((row) => (
                  <p key={row.id}>
                    <span>{row.description}</span>
                    <span align="right">${row.amount.toFixed(2)}</span>
                  </p>
                ))} */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                <p>
                  <span>Net Amount</span>
                  <span>${paymentSummaryData.netAmount}</span>
                </p>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                <p>
                  <span>Discount</span>
                  <span>
                    ${paymentSummaryData.promoCodeDiscount}
                  </span>
                </p>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>
                {PromocodeData.map((row) => (
                  <p key={row.id}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {row.description}{" "}
                    </Typography>
                  </p>
                ))}
                {/* <form onSubmit={handleApplyPromoCode}> */}
                <p className="applyPromo">
                  <TextField
                    label="Enter Code"
                    name="codeName"
                    variant="outlined"
                    value={paymentSummaryData.codeName}
                    onChange={handleChange}
                  />
                  <Button onClick={handleApplyPromoCode} variant="outlined">
                    Apply
                  </Button>
                </p>
                {promoCode !== null && promoCode !== "" ? (
                  <p>
                    <Chip
                      label={promoCode}
                      onClick={handleClick}
                      onDelete={handleDelete}
                      deleteIcon={<CloseIcon />}
                      variant="outlined"
                      sx={{ background: "#eee"}}
                    />
                  </p>
                ) : (
                  <></>
                )}
                {/* </form> */}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <p>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Total Amount Payable
                  </Typography>
                </p>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle2" fontWeight="bold" align="right">
                  ${paymentSummaryData.finalPrice}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow className="lastRowButton">
              <TableCell colSpan={2}>
                <Button
                  variant="contained"
                  className="LoginButton"
                  sx={{ py: 1, px: 2 }}
                  onClick={() => {
                    checkOutPayment();
                  }}
                >
                  Pay Now
                </Button>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    paddingLeft: "10px",
                  }}
                  onClick={() => {}}
                ></Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentSummary;
