import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import "./Menu.css";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSharedState } from "src/utils/SharedStateProvider";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
const buyerOptions = [
  {
    id: 1,
    label: "My Profile",
    path: "profile",
    icon: <PermIdentityOutlinedIcon />,
  },
  {
    id: 2,
    label: "Account Settings",
    path: "AccountSettings",
    icon: <LockOutlinedIcon />,
  },
  {
    id: 3,
    label: "Payment",
    path: "carddetails",
    icon: <CreditCardOutlinedIcon />,
  },
  {
    id: 4,
    label: "Calendar",
    path: "calendar",
    icon: <EditCalendarIcon />,
  },
  {
    id: 5,
    label: "Privacy, Terms & Conditions",
    path: "privacyterms",
    icon: <InsertDriveFileOutlinedIcon />,
  },
  {
    id: 6,
    label: "Support",
    path: "contactus",
    icon: <MailOutlineOutlinedIcon />,
  },
  {
    id: 7,
    label: "Logout",
    path: "logout",
    icon: <LogoutOutlinedIcon />,
  },
];

const sellerOptions = [
  {
    id: 1,
    label: "My Profile",
    path: "profile",
    icon: <PermIdentityOutlinedIcon />,
  },
  {
    id: 2,
    label: "Account Settings",
    path: "AccountSettings",
    icon: <LockOutlinedIcon />,
  },
  {
    id: 5,
    label: "Privacy & Terms",
    path: "privacyterms",
    icon: <InsertDriveFileOutlinedIcon />,
  },
  {
    id: 6,
    label: "Support",
    path: "contactus",
    icon: <MailOutlineOutlinedIcon />,
  },
  {
    id: 7,
    label: "Logout",
    path: "logout",
    icon: <LogoutOutlinedIcon />,
  },
];

const labels = [
  {
    id: 1,
    label: "My Profile",
  },
  {
    id: 2,
    label: "Account Settings",
  },
  {
    id: 3,
    label: "Wallet & Transactions",
  },
  {
    id: 4,
    label: "Calendar & Time-Slots",
  },
  {
    id: 5,
    label: "Privacy & Terms",
  },
  {
    id: 6,
    label: "Support",
  },
];

const ITEM_HEIGHT = 48;

export default function DotMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDilog, setOpenDilog] = useState(false);
  const { openMenu, setOpenMenu, setSelectedComponent } = useSharedState();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const role = parseInt(localStorage.getItem("role"));
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleMenuItemClick = (path, id) => {
    console.log("id", id);
    setSelectedComponent(labels.find((label) => label?.id === id)?.label);
    if (path === "logout") {
      setOpenDilog(true);
    } else {
      navigate(`/dashboard/${path}`);
    }
    handleClose();
  };
  const handelLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu) {
      setAnchorEl({});
    }
  }, [openMenu]);
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="update-profile"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        className="dot-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {role === 1
          ? buyerOptions.map((option) => (
              <MenuItem
                className={
                  option.id === 1
                    ? "tour-update-profile customMenuu"
                    : option.id === 5
                    ? "tour-privacy customMenuu"
                    : "customMenuu"
                }
                key={option.id}
                onClick={() => handleMenuItemClick(option.path, option.id)}
              >
                <div className="menu-item-content">
                  {option.icon}&nbsp;&nbsp;{option.label}
                </div>
              </MenuItem>
            ))
          : sellerOptions.map((option) => (
              <MenuItem
                className={
                  option.id === 1
                    ? "tour-update-profile customMenuu"
                    : option.id === 5
                    ? "tour-privacy customMenuu"
                    : "customMenuu"
                }
                key={option.id}
                onClick={() => handleMenuItemClick(option.path, option.id)}
              >
                <div className="menu-item-content">
                  {option.icon}&nbsp;&nbsp;{option.label}
                </div>
              </MenuItem>
            ))}
      </Menu>
      <Dialog open={openDilog} onClose={() => setOpenDilog(false)}>
        <DialogTitle>Are you Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Loging out your account</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDilog(false)}
            className="LoginButton"
            sx={{ color: "#FFFFFF" }}
          >
            Go Back
          </Button>
          <Button
            onClick={handelLogOut}
            sx={{ padding: "8px 14px" }}
            className="rejectButton"
          >
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
