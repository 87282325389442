import { Box } from "@mui/material";
import React from "react";
import AcceptRejectButtons from "../RightSidebar/AcceptReject";
import PopUp from "../RightSidebar/PopUp";
import ProjectCompleteConfirmation from "../RightSidebar/ProjectCompleteConfirmation";
import ProjectComplete from "../RightSidebar/ProjectComplete";
import { useJobSharedState } from "src/utils/jobStateProvider";

export default function Buttons() {
  const { userJob, rejectJob } = useJobSharedState();
  const [showMarkAsComplete, setShowMarkAsComplete] = React.useState(true);
  const role = parseInt(localStorage.getItem("role"));
  return (
    <Box>
      {parseInt(role) === 1 ? (
        <Box>
          {userJob.submittedContract === false && userJob.price ? (
            <PopUp />
          ) : (
            ""
          )}
        </Box>
      ) : (
        <Box>
          {!rejectJob && userJob.jobStatus === "waiting" ? (
            <AcceptRejectButtons />
          ) : (
            ""
          )}
        </Box>
      )}

      {userJob.jobStatus === "inprogress" &&
      role === 2 &&
      userJob.markAsComplete === false &&
      showMarkAsComplete ? (
        <ProjectCompleteConfirmation
          setShowMarkAsComplete={setShowMarkAsComplete}
        />
      ) : (
        ""
      )}
      {userJob.jobStatus === "inprogress" &&
      role === 1 &&
      userJob.markAsComplete ? (
        <ProjectComplete setShowMarkAsComplete={setShowMarkAsComplete} />
      ) : (
        ""
      )}
    </Box>
  );
}
