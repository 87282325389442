/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ElementWrapper from "src/components/payment/ElementWrapper";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import Loading from "./Loading";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublishableKey } from "src/constants/api.constants";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { paymentAPI } from "src/services/api";
import { userAPI } from "src/services/userApis";
import { Link } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import CoinImage from "../../Assets/coin-wallet.png";
const RechargeWallet = () => {
  const [savedCards, setSavedCards] = useState([]);
  const [newLoading, setNewLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [err, setErr] = useState("");
  const {
    setLoading,
    setChatLoading,
    setPaymentLoadScroll,
    //setConfirmPayment,
    //jobId,
    //rechargeDone,
    setRechargeDone,
  } = useSharedState();
  const {
    setCustomerId,
    paymentMethodId,
    setPaymentMethodId,
    customerId,
    setShowRechargeWallet,
  } = usePaymentState();
  const { userJob } = useJobSharedState();
  const [rechargeAmount, setRechargeAmount] = useState(
    Math.ceil(parseFloat(userJob.price)) || 0
  );
  const [showCardButtons, setShowCardButtons] = useState(true);
  const { walletUpdated, setWalletUpdated } = useSharedState();
  const [error, setError] = useState("");
  const { walletBalance, setWalletBalance } = useSharedState();
  // setChatLoading(true);
  const fetchCustomerId = async () => {
    try {
      await paymentAPI
        .getPaymentMethodByCustomer()
        .then(async (res) => {
          if (res.data.success === true) {
            setCustomerId(
              res.data.data.customerId ? res.data.data.customerId : null
            );
            setSavedCards([]);
            if (res.data.data.paymentMethods.length > 0) {
              res.data.data.paymentMethods.forEach(
                ({ billing_details, card, id }, index) => {
                  setSavedCards((previousItem) => [
                    ...previousItem,
                    {
                      id: id,
                      cardName: billing_details.name,
                      last4: card.last4,
                      exp_month: card.exp_month,
                      exp_year: card.exp_year,
                      brand: card.brand,
                      isDefault:
                        res.data.data.defaultPaymentMethod === id
                          ? async () => {
                              await setPaymentMethodId(id);
                              return true;
                            }
                          : false,
                    },
                  ]);
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // setChatLoading(false);

          toast.error("Something went wrong!!");
        })
        .finally(() => {
          setPaymentLoading(false);
          setNewLoading(false);
          setLoading(false);
          setChatLoading(false);
          setPaymentLoadScroll(true);
        });
    } catch (error) {
      setPaymentLoading(false);
      setChatLoading(false);
      setPaymentLoadScroll(true);
      toast.error("Something went wrong!!");
      console.log("Error fetching customer ID:", error);
    }
  };
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeObject = await loadStripe(stripePublishableKey);
        setStripe(stripeObject);
      } catch (error) {
        console.log("Error loading Stripe:", error);
        toast.error("Seomthing went wrong!");
      }
    };

    initializeStripe();
    // Fetch customer ID from your server/database and update the state
    // fetchPaymentSummary(jobId);
  }, []);
  useEffect(() => {
    // setChatLoading(true);
    // Fetch customer ID from your server/database and update the state
    setShowForm(false);
    fetchCustomerId();
  }, [paymentMethodId]);

  if (newLoading) {
    return <Loading />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowForm(!showForm);
  };

  const addTransactions = async (paymentIntentId, price, status) => {
    await paymentAPI
      .createTransaction({ paymentIntentId, price, status })
      .then((res) => {})
      .catch((err) => {
        console.log(err);

        toast.error("Seomthing went wrong!");
      });
  };

  const updateWalletBalance = async (balance) => {
    await paymentAPI
      .updateWallet({ balance })
      .then((res) => {})
      .catch((err) => {
        console.log(err);

        toast.error("Seomthing went wrong!");
      });
  };

  const getUser = () => {
    userAPI
      .getUserById()
      .then((res) => {
        setWalletBalance(res.data.body.data.wallet.balance);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const handleRechargeWallet = async (e) => {
    e.preventDefault();
    if (parseFloat(rechargeAmount) >= Math.ceil(parseFloat(userJob.price))) {
      setLoading(true);
      setError("");
      setErr("");
      await paymentAPI
        .createPaymentIntent({
          customerId,
          finalAmount: rechargeAmount,
        })
        .then(async (res) => {
          if (res.data.success) {
            // Use the PaymentIntent's client_secret to confirm the payment
            const confirmResult = await stripe.confirmCardPayment(
              res.data.data.client_secret,
              {
                payment_method: paymentMethodId,
              }
            );

            if (confirmResult.error) {
              console.log(
                "payment confirm error",
                confirmResult.error.payment_intent.id
              );
              await addTransactions(
                confirmResult.error.payment_intent.id,
                rechargeAmount,
                "failed"
              );
              console.log(confirmResult.error);
              // toast.error("Something went wrong!!");
              setError(confirmResult.error.message);
            } else {
              await addTransactions(
                confirmResult.paymentIntent.id,
                rechargeAmount,
                "success"
              );
              await updateWalletBalance(rechargeAmount);
              await getUser();
              setWalletUpdated(true);
              toast.success("Payment successfull");
            }
          }
          setLoading(false);
          setRechargeDone(true);
          setShowRechargeWallet(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!!");
        });
    }
    setErr("Please Enter amount greater than the job amount");
  };

  return (
    <>
      {" "}
      {paymentLoading ? (
        <Loading />
      ) : (
        <>
          {savedCards.length === 0 ? (
            <ElementWrapper />
          ) : (
            <>
              <Box
                className="MyCardContainer MyCardContainerChatArea"
                style={{ padding: "2%", marginLeft: "1%", width: "35%" }}
              >
                {/* <Grid container xl={12} > */}
                <Box>
                  <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
                    My Wallet
                  </Typography>
                </Box>
                <Box className="rechargeWalletBox">
                  <Typography>
                    Available balance&nbsp;
                    <ErrorIcon color="error" />
                  </Typography>
                  <Typography variant="h3">
                    ${walletBalance?.toFixed(2)}
                  </Typography>
                </Box>
                <Box className="rechargeWalletBox">
                  <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
                    Top-Up wallet
                  </Typography>
                  <TextField
                    type="number"
                    name="amount"
                    placeholder="amount*"
                    value={rechargeAmount}
                    onChange={(e) => {
                      setRechargeAmount(e.target.value);
                    }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                  />
                  <Typography>Recommeded</Typography>
                  <Chip
                    label="$ 100"
                    onClick={() => {
                      setRechargeAmount(100);
                    }}
                    style={{
                      backgroundColor: "#E0E0DB",
                      color: "black",
                      borderRadius: "50px",
                    }}
                  />
                  <Chip
                    label="$ 500"
                    onClick={() => {
                      setRechargeAmount(500);
                    }}
                    style={{
                      backgroundColor: "#E0E0DB",
                      color: "black",
                      borderRadius: "50px",
                    }}
                  />
                  <Chip
                    label="$ 1000"
                    onClick={() => {
                      setRechargeAmount(1000);
                    }}
                    style={{
                      backgroundColor: "#E0E0DB",
                      color: "black",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
                <Box width="95%" sx={{ marginTop: "4%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Align items in the center vertically
                      backgroundColor: "#D7C6F0",
                      boxShadow: "5px 5px #B4ACC1",
                      width: "100%",
                      padding: "10px 10px 0px 10px",
                      borderRadius: "30px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    <img
                      alt="coinImage"
                      src={CoinImage}
                      style={{ height: "12%", width: "12%" }}
                    />
                    <p
                      style={{
                        fontSize: "18px",
                        marginLeft: "10px",
                        overflowWrap: "break-word",
                      }}
                    >
                      Get cashback of $50 on topup of $500
                    </p>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "7%" }}>
                  <Button
                    className="LoginButton"
                    variant="contained"
                    onClick={handleRechargeWallet}
                    sx={{ width: "100%" }}
                  >
                    Proceed to TopUp
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px 0px",
                      width: "100%",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "grey" }}
                      variant="body2"
                      onClick={() => {
                        console.info("I'm a button.");
                      }}
                    >
                      Pay with Card
                    </Link>
                  </Box>
                </Box>
                {/* <Grid container item xs={12} sx={{ paddingBottom: 2 }}>
                    <Grid item xs={6}>
                      <Typography variant="h4" sx={{ paddingLeft: "7%" }}>
                        My Cards
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Button
                        variant="contained"
                        className="LoginButton"
                        sx={{ paddingRight: "5%" }}
                        onClick={handleSubmit}
                      >
                        {showForm ? "Cancel" : "Add New Card"}
                      </Button>
                    </Grid>
                  </Grid> */}
                {/* </Grid> */}
                {/* <MyCardsList
                  savedCards={savedCards}
                  handleSubmit={handleSubmit}
                  showForm={showForm}
                  showCardButtons={showCardButtons}
                /> */}
                {/* {showForm ? (
                  <ElementWrapper />
                ) : (
                    <>
                    <Grid container spacing={2}>
                      <Grid item xs={5} sx={{ marginLeft: "4%" }}>
                        <Grid item>
                          <TextField
                            id="outlined-basic"
                            label="Amount"
                            variant="outlined"
                            name="problemTitle"
                            type="number"
                            placeholder="Enter Amount"
                            value={rechargeAmount}
                            onChange={(e) => {
                              setRechargeAmount(e.target.value);
                            }}
                            
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                  display: "none",
                                },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                            }}
                          />
                          <FormHelperText sx={{color:"#d32f2f"}}>{err}</FormHelperText>
                        </Grid>
                        <Grid sx={{ marginTop: 2 }}>
                          <Button
                            className="LoginButton"
                            variant="contained"
                            onClick={handleRechargeWallet}
                          >
                            Pay
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={7}></Grid>
                    </Grid>
                  </>
                
                )} */}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RechargeWallet;
